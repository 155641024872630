<template>
    <div class="list-index">
        <!--        <top-menu />-->
        <div class="content" :style="{width: cid ? '100%' : ''}">
            <el-card  class="box-card">
                <el-form>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="检测标准">
                                <el-input @keyup.enter.native="getList(true)" v-model="formInline.detection_standard" placeholder="请输入检测标准(方法)" clearable></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="检测对象">
                                <el-input @keyup.enter.native="getList(true)" v-model="formInline.detection_obj" placeholder="请输入检测对象" clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="检测项目">
                                <el-input @keyup.enter.native="getList(true)" v-model="formInline.detection_items" placeholder="请输入检测项目" clearable></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                    <el-row>
<!--                        <el-col :span="8" >-->
<!--                            <el-form-item label="实验室名" v-if="!cid">-->
<!--                                <el-input @keyup.enter.native="getList(true)" v-model="formInline.institution_name" placeholder="请输入实验室名称" clearable></el-input>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
                        <el-col :span="8" style="min-height: 1px" >
                            <el-form-item label="所属城市" v-if="!cid">
                                <!--         <el-select v-model="formInline.city" placeholder="活动区域">
                                             <el-option label="区域一" value="shanghai"></el-option>
                                             <el-option label="区域二" value="beijing"></el-option>
                                         </el-select>-->
                                <el-cascader
                                        v-model="curCity"
                                        filterable
                                        clearable
                                        placeholder="请选择城市"
                                        size="medium"
                                        :options="options"
                                        @keyup.enter.native="getList(true)"
                                        @change="handleChange">
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="cid? 24 : 15"  class="search-btn">
                            <el-form-item :style="{ float: 'right' }">
<!--                            <el-form-item :style="{ float: cid ? 'right': 'unset'}">-->
                                <el-button type="primary"  @click="getList(true)">开始检索</el-button>
                                <el-button type="primary" plain @click="reset()">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <el-card class="box-card list-card">
                <div slot="header" class="clearfix">
                    <span v-if="!listData.isRecommend"><span v-if="formInline.search">搜索关键字“{{formInline.search}}”,</span>为您找到{{listData.total}}条数据</span>
                    <span v-else>筛选条件未查询到数据，为您推荐以下结果</span>
                </div>
                <el-table
                        v-loading="loading"
                        :data="tableData"
                        height="700"
                        stripe
                        >
                    <el-table-column
                            align="center"
                            v-for="(item, index) in tableColumn"
                            :key="index"
                            :prop="item.prop"
                            :label="item.label"
                            :show-overflow-tooltip="item.prop === 'test_description'"

                    >
                        <template slot-scope="scope" >
                            <span v-if="item.prop === 'qualifications'"><span>{{$formatQuality(scope.row[item.prop]).map(it => it.text).join(',')}}</span></span>
                            <el-link class="link" @click="goChannel(scope.row)" v-else-if="item.prop === 'institution_name'"><span v-html="scope.row[item.prop]"></span></el-link>
                            <div v-else-if="item.prop === 'op'"  style="display: inline-flex;">
                              <div v-if="!cid">
                                <el-button @click="goDetail(scope.row)" type="warning" size="small" >查看详情</el-button>
                              </div>
                              <div v-else>
                                <el-button type="warning" @click.stop="goRead(scope.row)" size="small">标准预览</el-button>
                                <el-button type="danger"  @click.stop="down(scope.row)" size="small" >下载文档</el-button>
                              </div>

<!--                              <el-button @click="down(scope.row)" type="warning" size="small" plain>相关文档下载</el-button>-->
                            </div>
<!--                            <span  v-if="item.prop === 'test_description'">-->
<!--                            </span>-->
                            <span @click="clickCopy(scope.row[item.prop])"  v-else v-html="scope.row[item.prop]"></span>
                        </template>

                    </el-table-column>
                  <el-table-column
                      fixed="right"
                      align="center"
                      label="操作"
                      :width="!cid ? 100 : 200">
                    <template slot-scope="scope">
                      <div v-if="!cid">
                        <el-button @click="goDetail(scope.row)" type="warning" size="small" >查看详情</el-button>
                      </div>
                      <div v-else>
                        <el-button type="warning" @click.stop="goRead(scope.row)" size="small">标准预览</el-button>
                        <el-button type="danger"  @click.stop="down(scope.row)" size="small" >下载文档</el-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <!--                <el-empty description="暂无数据" :image-size="100" v-show=" listData.list.length === 0"></el-empty>-->
            </el-card>
            <el-pagination
                    style="text-align: center"
                    background
                    layout="sizes, prev, pager, next, jumper"
                    :current-page="listData.current_page"
                    :page-size="Number(listData.per_page)"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-count="listData.last_page"
                    @size-change="handleSizeChange"
                    @current-change="currentChange"
                    :total="listData.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { archivesService } from '@/api/archives'
    import { provinceAndCityDataPlus, CodeToText } from 'element-china-area-data'
    import {setSearchParams, getSearchParams } from "@/utils/storageUtils";
    export default {
        name: "list-1",
        props: ['cid', 'keyWord', 'formDetailRead'],
        data() {
            return {
                formRoute: {},
                loading: false,
                options: provinceAndCityDataPlus,
                curCity: [],
                tableData: [],
                tableColumn: [
                    // {
                    //     prop: 'institution_name',
                    //     label: '实验室名',
                    //     width: '250'
                    // },
                    {
                        prop: 'detection_items',
                        label: '检测项目',
                        width: '120'
                    },
                    {
                        prop: 'detection_obj',
                        label: '检测对象',
                        width: '120'
                    },
                    {
                        prop: 'standard_code',
                        label: '标准号',
                      // width: '120'
                    },
                    {
                        prop: 'detection_standard',
                        label: '检测标准',
                    },
                    {
                        prop: 'test_description',
                        label: '试验说明',
                        width: ''
                    },
                  //   {
                  //       prop: 'cycle',
                  //       label: '周期',
                  //       width: ''
                  //   },
                  // // {
                  // //   prop: 'cycle',
                  // //   label: '周期',
                  // //   width: ''
                  // // },
                  //   {
                  //       prop: 'average_price',
                  //       label: '均价',
                  //       width: ''
                  //   },
                    // {
                    //     prop: 'qualifications',
                    //     label: '资质',
                    //     width: '120'
                    // },
                    // {
                    //     prop: 'op',
                    //     label: '操作',
                    //     width: ''
                    // }
                ],
                formInline: {
                    longitude: '',
                    latitude: '',
                    cityCode: [],
                    search: '',
                    detection_obj: '',
                    institution_name: '',
                    detection_items: '',
                    detection_standard: '',
                    major_equipment: '',
                    city: '',
                    province: '',
                    page: 1,
                    pageSize: 10
                },
                listData: {
                    current_page: 1,
                    isRecommend: false,
                    last_page: 1,
                    list: [],
                    per_page: 1,
                    total: 0
                }
            }
        },
        methods: {
            clickCopy(data) {
              const text = data.replace(/<em>/g, '').replace(/<\/em>/g, '');
              console.log('text', text)
              const textField = document.createElement('textarea');
              textField.innerText = text;
              document.body.appendChild(textField);
              textField.select();
              document.execCommand('copy');
              textField.remove();
              this.$message.success('已复制');
            },
            goChannel(item) {
              if (this.cid) return
              this.$router.push({
                query: {
                  id: item.cid
                },
                name: 'detailLaboratory'
              })
            },
            down(item) {
                this.$pay.show({
                    id: item.id,
                    type: 'down'
                }
              )
                // // // console.log('this.$pay', this.$pay)
            },
            goRead(item) {
                this.$router.push({
                    name: 'detailRead',
                    query: {
                        id: item.id
                    }
                })
            },
            goDetail(item) {
                // // console.log('item', item)
              // const loading = this.$loading({
              //   lock: true,
              //   text: '加载中...',
              //   spinner: 'el-icon-loading',
              // });
              const to = (item2 = item) => {
                this.$router.push({
                    query: {
                        id: item2.id
                    },
                  params: {
                    search: this.formInline.search,
                    view: true,
                  },
                    name: 'detail'
                })
              }
              const data = item
              const params = {
                page: this.recommendList_2Page,
                // aid: data.id,
                // latitude: point.lat,
                // longitude: point.lng,
                pageSize: 1,
                isRecommend: true,
                detection_obj: data.baseItemData.detection_obj,
                detection_items: data.baseItemData.detection_items,
                standard_code: data.baseItemData.standard_code,
              }
              archivesService.list(params).then(res => {
                // console.log('res', res)
                console.log('res222', res)
                if (res.data.total > 0) {
                  to(res.data.list[0])
                } else {
                  to()
                }

              }).catch(() => {
                to()
              })

            },
            reset() {
                // const point = getPointData()
                this.curCity = []
                this.formInline = {
                    // latitude: point.lat,
                    // longitude: point.lng,
                    cityCode: [],
                    search: '',
                    institution_name: '',
                    detection_obj: '',
                    detection_items: '',
                    standard_code: '',
                    detection_standard: '',
                    major_equipment: '',
                    city: '',
                    province: '',
                    page: 1,
                    pageSize: 10
                }
                this.$parent.keyWord = ''
                // this.getList()
            },
            formatCity(item) {
                return (() =>{
                    let province = item[0] ? CodeToText[item[0]] : ''
                    let city = item[1] ? CodeToText[item[1]] : ''
                    if (city === '市辖区') {
                        city = ''
                    }
                    // if (province.includes('市')) {
                    //     province = province.split('市')[0]
                    // }
                    this.formInline.province = province
                    this.formInline.city = city
                    if (!province && !city) return ''
                    if (province && !city) return province
                    return `${province}/${city}`
                })()
            },
            handleChange(item) {
                // // console.log('item', item)
                // // // console.log(CodeToText[item[0]], CodeToText[item[1]])
                this.formInline.cityCode = item
                // this.formInline.city = this.formatCity(item)
                this.formatCity(item)
            },
            currentChange(page) {
                this.formInline.page = page
                this.getList()
            },
            handleSizeChange(size) {
              // console.log(size)
              this.formInline.pageSize = size
              this.getList()
            },
            getList(isSearch) {

              const { detection_standard, detection_obj, detection_items,  cityCode} = this.formInline
                if (!this.cid && !detection_standard && !detection_obj && !detection_items && !cityCode.length) {
                  this.$message.warning('请至少输入一个检索条件')
                  return
                }
                if (isSearch) {
                    this.formInline.page = 1
                    this.formInline.search = ''
                }
              const heightKey = ['detection_obj', 'standard_code', 'detection_items', 'detection_standard']
              heightKey.forEach(key => {
                if (this.formInline[key]) {
                  this.formInline[key] = this.formInline[key].replaceAll(' ', '').replaceAll('；', ';')
                }
              })
              // this.formInline.detection_standard = this.formInline.detection_standard.replaceAll(' ', '')
              let params = {
                  ...{},
                  ...this.formInline
              }
                /*            if (this.formRoute.name === 'detail') {
                                 params = {
                                    ...params,
                                    ...getSearchParams()
                                }
                            }*/

                if (this.cid) {
                  params.cid = this.cid
                }
                if (this.$route.name === 'ListAbility') {
                  setSearchParams(params)
                }
                this.loading = true
                archivesService.list(params, true).then(res => {
                    res.data.list.forEach(item => {
                      item.baseItemData = {
                        detection_obj: item.detection_obj,
                        detection_items: item.detection_items,
                        standard_code: item.standard_code,
                      }
                        if (params.search) {
                            heightKey.map(key => {
                                if ((key in params) && item[key].includes(params.search)) {
                                  item[key] = item[key].replace(params.search, `<em>${params.search}</em>`)
                                }
                            })
                        } else {
                            heightKey.map(key => {
                                if (params[key] && item[key].includes(params[key])) {
                                    if (key === 'detection_standard') {
                                        // debugger
                                        item['standard_code'] = item['standard_code'].replace(params['detection_standard'], `<em>${params['detection_standard']}</em>`)
                                    }
                                    item[key] = item[key].replace(params[key], `<em>${params[key]}</em>`)

                                }
                            })
                        }
                        // item.provinceCity = `${item.province}/${item.city}`
                    })
                    this.listData = res.data
                    this.tableData = res.data.list
                    // // console.log('this.listData', this.listData)
                }).finally(() => {
                  this.loading = false
                })
            }
        },
        mounted() {
            if (this.cid) {
              const index = this.tableColumn.findIndex(item => item.prop === 'test_description')
              this.$set(this.tableColumn, index, {
                prop: 'detection_instructions',
                label: '限制说明',
                width: ''
              })
              this.tableColumn.push({
                prop: 'qualifications',
                label: '资质',
                width: ''
              })
              if (this.formDetailRead) {
                this.formInline = getSearchParams()
              }
              this.getList()
              // this.tableColumn[index] =    {
              //   prop: 'detection_instructions',
              //   label: '限制说明',
              //   width: ''
              // }
            }
            setTimeout(() => {
              // console.log('this.formInline', this.formInline)
              const { detection_standard, detection_obj, detection_items,  cityCode} = this.formInline
              if (!detection_standard && !detection_obj && !detection_items && !cityCode.length) {
                return
              }
              this.getList()
            }, 200)

        },
        beforeRouteEnter(to, form, next) {

            // to.meta.keepAlive = to.name === 'detail'
            // console.log(222)
            next(vm => {
              console.log('vm', vm, to, form)
                vm.formRoute = form
                if (form.name === 'detail' && vm.$route.name !== 'detailLaboratory'){

                    vm.formInline = getSearchParams()
                    vm.curCity = vm.formInline.cityCode
                    // vm.$parent.keyWord = vm.formInline.search
                    vm.formatCity(vm.formInline.cityCode)
                    // vm.formInline.city = vm.formatCity(vm.formInline.cityCode)
                } else {
                    // const point = getPointData()
                    // vm.formInline.latitude = point.lat
                    // vm.formInline.longitude = point.lng
                    // if (vm.$parent.keyWordType == 1) {
                    //   vm.formInline.search = vm.$parent.keyWord
                    // } else {
                    //   vm.formInline[vm.$parent.keyWordType] = vm.$parent.keyWord
                    // }
                  vm.formInline[vm.$parent.keyWordType] = vm.$parent.keyWord
                  vm.formInline.search = vm.$parent.keyWord
                }
            })
        }
    }
</script>
<style  lang="scss">
    .list-index{
        em{
            color: $danger;
            font-style: normal !important;
        }
      .el-table__body{
        overflow: hidden;
      }
    }
</style>
<style scoped lang="scss">

    .list-index{
        .link{
            border-bottom: 1px solid $font_3;
        }
        .el-input, .el-cascader {
            width: 70%;
        }
        .content{
            width: 85%;
            //width: 85%;
            height: 100%;
            margin: 0 auto;
        }
        .text {
            font-size: 14px;
        }

        .item {
            margin-bottom: 18px;
        }

        .clearfix:before,
        .clearfix:after {
            display: table;
            content: "";
        }
        .clearfix:after {
            clear: both
        }

        .box-card {
            /*width: 480px;*/
            margin: 15px 0;
            .search-btn{
                .el-button{
                    width: 160px;
                    padding: 9px 10px;
                }
            }
        }

        .list-card{
            height: 100vh;
            overflow: auto;
            /*padding: 10px 5px;*/
            ::v-deep.el-card__body{
                padding: 0 10px;
            }
            .list-item{
                cursor: pointer;
                display: flex;
                border-radius: 2px;
                border-bottom: 1px solid $border_2;
                align-items: center;
                padding:  10px 0;
                .left{
                    display: block;
                    line-height: 1.5;
                    flex: 0.5;
                    .title{
                        font-size:16px;
                        font-family:PingFangSC-Semibold,PingFang SC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        line-height:28px;
                    }
                    .description{
                        font-size:14px;
                        font-weight:400;
                        line-height:20px;
                        margin-top: 6px;
                        color: $font_1;
                    }
                    .search_line{
                        color: $font_2;
                        font-size: 13px;
                    }
                    .time{
                        font-size:12px;
                        font-weight:400;
                        margin-top: 6px;
                        color: $font_2
                    }

                }
                .center{
                    flex: 0.3;
                    /*margin-top: 7px;*/
                    .labels {
                        border-radius:2px;

                        div{
                            /*width: 90px;*/
                            /*border:1px solid rgba(184,180,255,1);*/
                            font-size: 14px;
                            padding: 5px;
                            color: $font_2;
                        }
                    }
                }
                .right{
                    flex: 0.2;
                    line-height: 1.4;
                    text-align: center;
                    display: block;
                    cursor: pointer;
                    p{
                        color: $danger;
                        font-size: 18px;
                        font-weight: 500;
                        margin: 5px 0;
                    }
                }
            }
        }
    }

</style>
